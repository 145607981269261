import React, { Component } from "react";

export default class CourseworksImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="737.66564"
        height="594"
        viewBox="0 0 737.66564 594"
      >
        <path
          d="M499.59339,603.57371a24.30012,24.30012,0,0,1,9.74324-30.08516c1.64736-1.00163.14149-3.59709-1.51416-2.59041A27.0077,27.0077,0,0,0,497.003,605.08787c.75686,1.76976,3.34195.24315,2.59041-1.51416Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <path
          d="M500.45155,607.60854c-4.04629-7.16841-3.0941-15.747-1.63872-23.4989,1.51929-8.09231,3.51088-16.30868,1.78772-24.539a22.527,22.527,0,0,0-5.353-10.73372c-1.31056-1.42342-3.42784.70229-2.12132,2.12132,5.0888,5.527,5.67878,13.5448,4.85343,20.66543-.9735,8.39864-3.73258,16.58266-3.39006,25.117a27.20192,27.20192,0,0,0,3.27155,12.38207,1.50112,1.50112,0,0,0,2.59041-1.51416Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <path
          d="M522.16718,630.5h-51a4.50508,4.50508,0,0,1-4.5-4.5V611.01953A14.53617,14.53617,0,0,1,481.187,596.5h7.48023a1.50164,1.50164,0,0,0,1.5-1.5v-7a2.50294,2.50294,0,0,1,2.5-2.5h8a2.50294,2.50294,0,0,1,2.5,2.5v7a1.50164,1.50164,0,0,0,1.5,1.5h7.48022a14.53618,14.53618,0,0,1,14.51978,14.51953V626A4.50508,4.50508,0,0,1,522.16718,630.5Z"
          transform="translate(-231.16718 -153)"
          fill="#ccc"
        />
        <path
          d="M497.45105,540.53959c1.07377,5.69871-.25563,10.73293-2.9693,11.24425s-5.784-3.69389-6.85778-9.3926,2.22864-15.17508,2.22864-15.17508S496.37727,534.84088,497.45105,540.53959Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M518.0446,567.052c-3.40041,4.69739-7.97031,7.19273-10.20717,5.57349s-1.2936-6.73989,2.10681-11.43729,12.55268-8.81363,12.55268-8.81363S521.445,562.35458,518.0446,567.052Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M405.59339,243.57371a24.30012,24.30012,0,0,1,9.74324-30.08516c1.64736-1.00163.14149-3.59709-1.51416-2.59041A27.0077,27.0077,0,0,0,403.003,245.08787c.75686,1.76976,3.34195.24315,2.59041-1.51416Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <path
          d="M406.45155,247.60854c-4.04629-7.16841-3.0941-15.747-1.63872-23.4989,1.51929-8.09231,3.51088-16.30868,1.78772-24.539a22.527,22.527,0,0,0-5.353-10.73372c-1.31056-1.42342-3.42784.70229-2.12132,2.12132,5.0888,5.527,5.67878,13.5448,4.85343,20.66543-.9735,8.39864-3.73258,16.58266-3.39006,25.117a27.20192,27.20192,0,0,0,3.27155,12.38207,1.50112,1.50112,0,0,0,2.59041-1.51416Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <path
          d="M428.16718,270.5h-51a4.505,4.505,0,0,1-4.5-4.5V251.01953A14.53623,14.53623,0,0,1,387.18683,236.5h7.48035a1.50164,1.50164,0,0,0,1.5-1.5v-7a2.50286,2.50286,0,0,1,2.5-2.5h8a2.50286,2.50286,0,0,1,2.5,2.5v7a1.50164,1.50164,0,0,0,1.5,1.5h7.48035a14.53623,14.53623,0,0,1,14.51965,14.51953V266A4.505,4.505,0,0,1,428.16718,270.5Z"
          transform="translate(-231.16718 -153)"
          fill="#ccc"
        />
        <path
          d="M403.45105,180.53959c1.07377,5.69871-.25563,10.73293-2.9693,11.24425s-5.784-3.69389-6.85778-9.3926,2.22864-15.17508,2.22864-15.17508S402.37727,174.84088,403.45105,180.53959Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M424.0446,207.052c-3.40041,4.69739-7.97031,7.19273-10.20717,5.57349s-1.2936-6.73989,2.10681-11.43729,12.55268-8.81363,12.55268-8.81363S427.445,202.35458,424.0446,207.052Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <rect
          x="32"
          y="272"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <rect
          x="32"
          y="321"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <path
          d="M554.16718,746h-306a17.01909,17.01909,0,0,1-17-17V170a17.01909,17.01909,0,0,1,17-17h306a17.01917,17.01917,0,0,1,17,17V729A17.01917,17.01917,0,0,1,554.16718,746Zm-306-591a15.017,15.017,0,0,0-15,15V729a15.017,15.017,0,0,0,15,15h306a15.017,15.017,0,0,0,15-15V170a15.017,15.017,0,0,0-15-15Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect x="1" y="115.45264" width="338" height="2" fill="#3f3d56" />
        <rect x="1" y="235.48438" width="338" height="2" fill="#3f3d56" />
        <rect x="1" y="355.51563" width="338" height="2" fill="#3f3d56" />
        <rect x="1" y="475.54736" width="338" height="2" fill="#3f3d56" />
        <path
          d="M301.16718,511h-34a5.0058,5.0058,0,0,1-5-5V409a5.0058,5.0058,0,0,1,5-5h34a5.0058,5.0058,0,0,1,5,5v97A5.0058,5.0058,0,0,1,301.16718,511Zm-34-105a3.00335,3.00335,0,0,0-3,3v97a3.00335,3.00335,0,0,0,3,3h34a3.00336,3.00336,0,0,0,3-3V409a3.00336,3.00336,0,0,0-3-3Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect
          x="95"
          y="272"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <rect
          x="95"
          y="321"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <path
          d="M364.16718,511h-34a5.0058,5.0058,0,0,1-5-5V409a5.0058,5.0058,0,0,1,5-5h34a5.0058,5.0058,0,0,1,5,5v97A5.0058,5.0058,0,0,1,364.16718,511Zm-34-105a3.00335,3.00335,0,0,0-3,3v97a3.00335,3.00335,0,0,0,3,3h34a3.00336,3.00336,0,0,0,3-3V409a3.00336,3.00336,0,0,0-3-3Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect
          x="195"
          y="151"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <rect
          x="195"
          y="200"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <path
          d="M464.16718,390h-34a5.0058,5.0058,0,0,1-5-5V288a5.0058,5.0058,0,0,1,5-5h34a5.00573,5.00573,0,0,1,5,5v97A5.00573,5.00573,0,0,1,464.16718,390Zm-34-105a3.00335,3.00335,0,0,0-3,3v97a3.00335,3.00335,0,0,0,3,3h34a3.00328,3.00328,0,0,0,3-3V288a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect
          x="258"
          y="151"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <rect
          x="258"
          y="200"
          width="41"
          height="15"
          fill={theme.imageHighlight}
        />
        <path
          d="M527.16718,390h-34a5.00573,5.00573,0,0,1-5-5V288a5.00573,5.00573,0,0,1,5-5h34a5.00573,5.00573,0,0,1,5,5v97A5.00573,5.00573,0,0,1,527.16718,390Zm-34-105a3.00328,3.00328,0,0,0-3,3v97a3.00328,3.00328,0,0,0,3,3h34a3.00328,3.00328,0,0,0,3-3V288a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect
          x="380.40526"
          y="422.77357"
          width="41"
          height="15"
          transform="translate(-356.89697 15.51816) rotate(-20.59468)"
          fill={theme.imageHighlight}
        />
        <rect
          x="397.64125"
          y="468.64209"
          width="41"
          height="15"
          transform="translate(-371.92993 24.51236) rotate(-20.59468)"
          fill={theme.imageHighlight}
        />
        <path
          d="M411.3111,509.88281a5.009,5.009,0,0,1-4.67786-3.24414L372.513,415.83789a5.00607,5.00607,0,0,1,2.92151-6.439l31.82739-11.95948a4.99925,4.99925,0,0,1,6.43921,2.92188l34.12024,90.80078a5.00606,5.00606,0,0,1-2.92151,6.439h-.00012l-31.82727,11.95948A4.99586,4.99586,0,0,1,411.3111,509.88281Zm-2.2887-110.76465a3.00572,3.00572,0,0,0-1.057.19336L376.13825,411.271a3.00388,3.00388,0,0,0-1.75305,3.86377l34.12024,90.80078a2.99969,2.99969,0,0,0,3.86352,1.75293L444.19611,495.729a3.00388,3.00388,0,0,0,1.75305-3.86377l-34.12024-90.80078a3.00565,3.00565,0,0,0-2.80652-1.94629Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <rect
          x="242.40526"
          y="303.77357"
          width="41"
          height="15"
          transform="translate(-323.85743 -40.62902) rotate(-20.59468)"
          fill={theme.imageHighlight}
        />
        <rect
          x="259.64125"
          y="349.64209"
          width="41"
          height="15"
          transform="translate(-338.89039 -31.63483) rotate(-20.59468)"
          fill={theme.imageHighlight}
        />
        <path
          d="M273.3111,390.88281a5.009,5.009,0,0,1-4.67786-3.24414L234.513,296.83789a5.00607,5.00607,0,0,1,2.92151-6.439l31.82739-11.95948a4.99925,4.99925,0,0,1,6.43921,2.92188l34.12024,90.80078a5.00606,5.00606,0,0,1-2.92151,6.439h-.00012l-31.82727,11.95948A4.99586,4.99586,0,0,1,273.3111,390.88281Zm-2.2887-110.76465a3.00572,3.00572,0,0,0-1.057.19336L238.13825,292.271a3.00388,3.00388,0,0,0-1.75305,3.86377l34.12024,90.80078a2.99969,2.99969,0,0,0,3.86352,1.75293L306.19611,376.729a3.00388,3.00388,0,0,0,1.75305-3.86377l-34.12024-90.80078a3.00565,3.00565,0,0,0-2.80652-1.94629Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <circle cx="627" cy="219" r="31" fill="#2f2e41" />
        <path
          d="M720.44751,513.47105a10.74273,10.74273,0,0,0,14.42391-7.956l85.23707-53.77848-15.51422-17.467-78.94343,58.39088a10.80091,10.80091,0,0,0-5.20333,20.81064Z"
          transform="translate(-231.16718 -153)"
          fill="#ffb8b8"
        />
        <path
          d="M802.31342,466.03418a4.50412,4.50412,0,0,1-3.79321-2.06348l-9.87061-15.33886a4.5006,4.5006,0,0,1,1.14819-6.082l33.03-23.873a14.78876,14.78876,0,1,1,16.62964,24.44531l-34.72925,22.20508A4.46628,4.46628,0,0,1,802.31342,466.03418Z"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <polygon
          points="585.894 580.85 573.635 580.849 567.802 533.561 585.897 533.562 585.894 580.85"
          fill="#ffb8b8"
        />
        <path
          d="M564.8774,577.34625h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H549.99054a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,564.8774,577.34625Z"
          fill="#2f2e41"
        />
        <polygon
          points="643.128 571.684 631.324 574.998 612.926 531.047 630.347 526.156 643.128 571.684"
          fill="#ffb8b8"
        />
        <path
          d="M854.96246,725.53151h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H840.0756a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,854.96246,725.53151Z"
          transform="translate(-397.28783 106.53262) rotate(-15.68037)"
          fill="#2f2e41"
        />
        <path
          d="M907.32709,652.11328l-47.596-56.1709,28.88135-35.04394a4.49962,4.49962,0,0,1,6.96118.02051l43.70166,53.63378Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M861.6514,596.36047h0a15.94248,15.94248,0,0,1,2.58625-3.45319l27.56723-27.94751a6.13368,6.13368,0,0,1,4.0758-1.83c3.14992-.14873,8.31574.16954,9.75565,3.62537l-37.218,30.45111Z"
          transform="translate(-231.16718 -153)"
          fill="#f2f2f2"
        />
        <path
          d="M908.98114,663.999a11.4306,11.4306,0,0,1-5.95776-1.88672l-.13159-.13574-43.59155-66.66894,7.23339-.69922,38.76661-30.23828a4.50045,4.50045,0,0,1,6.52221,1.06738l42.37622,64.1377a4.51307,4.51307,0,0,1-1.238,6.21093l-41.00611,27.65039A7.94866,7.94866,0,0,1,908.98114,663.999Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M893.56391,618.39355a2.49929,2.49929,0,0,1-2.0774-1.10253l-5.00781-7.43848a2.50383,2.50383,0,0,1,.67774-3.4707l25.69116-17.2959a2.505,2.505,0,0,1,3.47021.67773l5.00757,7.43848a2.50383,2.50383,0,0,1-.67773,3.4707l-25.69116,17.2959A2.49073,2.49073,0,0,1,893.56391,618.39355Z"
          transform="translate(-231.16718 -153)"
          fill="#3f3d56"
        />
        <path
          d="M797.02948,726.69824a5.47917,5.47917,0,0,1-5.43872-5.0039c-2.18188-24.78516-13.12671-149.7002-14.32812-174.92676-1.33448-28.0293,21.99267-72.3711,22.22851-72.81543l.16138-.30469,11.52979,1.44043v1.34082l10.97436,1.291,21.23828,2.05566,6.08032,74.
31836,2.68848,50.40234L877.423,707.43945a4.476,4.476,0,0,1-3.18628,5.41407l-21.9834,5.99511a4.51906,4.51906,0,0,1-5.51221-3.10937l-20.69751-72.707a1.5,1.5,0,0,0-2.94189.457l2.189,70.89551a5.34339,5.34339,0,0,1-1.61718,3.91211c-5.03907,4.73242-18.35352,7.25878-25.84131,8.34375A5.5788,5.5788,0,0,1,797.02948,726.69824Z"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <path
          d="M863.08564,414.39249c-3.48829-3.63648-26.47315,1.36093-31.57618,6.71838l-34.93575,57.10624,57.77809,10.74941L867.11673,416.408Z"
          transform="translate(-231.16718 -153)"
          fill={theme.imageHighlight}
        />
        <path
          d="M847.94159,559.47949a11.248,11.248,0,0,1-3.65747-.60937c-3.35669-1.15137-7.4707-2.35645-12.22754-3.5791-20.83374-5.35743-28.18945-22.50977-21.8623-50.98047l.15991-.72266A210.7341,210.7341,0,0,1,843.311,428.3252l2.45-3.51954a26.398,26.398,0,0,1,27.04492-10.75586c9.37427,1.9336,14.40747,4.73536,14.96021,8.32813,1.08471,7.0498-19.854,92.26953-28.90674,128.582a11.23544,11.23544,0,0,1-10.91773,8.51953Z"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <path
          d="M776.17108,548.53906a5.283,5.283,0,0,1-1.60522-.25l-6.75269-2.16015a5.26875,5.26875,0,0,1-3.24047-7.082L800.404,454.94336a78.69258,78.69258,0,0,1,34.12354-37.82715h.00024a1.84145,1.84145,0,0,1,1.46143-.13672,1.6571,1.6571,0,0,1,1.00488.8916c4.21411,9.27051-18.72241,53.14356-37.15234,88.39551-9.43726,18.05078-17.5874,33.64063-18.51514,38.08594a5.26334,5.26334,0,0,1-5.15552,4.18652Z"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <path
          d="M887.026,567.70345a10.74266,10.74266,0,0,0,.92142-16.44683l-.28478-100.78395-23.04006,3.86569,7.53584,97.90185A10.8009,10.8009,0,0,0,887.026,567.70345Z"
          transform="translate(-231.16718 -153)"
          fill="#ffb8b8"
        />
        <path
          d="M868.2663,475.47656a4.49932,4.49932,0,0,1-4.48853-4.20312l-2.68017-40.666A14.80742,14.80742,0,0,1,874.882,414.877a14.78957,14.78957,0,0,1,15.761,14.626l.364,41.21973a4.50852,4.50852,0,0,1-4.44678,4.54l-18.23925.21386Z"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <circle cx="620.61417" cy="227.01575" r="24.56103" fill="#ffb8b8" />
        <path
          d="M872.2028,397.202c1.83183-5.82965,3.51355-12.70329-.11157-17.62253-2.61207-3.54455-7.30192-4.91657-11.70057-5.113s-8.80988.54917-13.19907.20024-9.028-2.08127-11.25764-5.878c-2.89119-4.92317-.44247-11.70412,4.20553-15.01964s10.78836-3.77847,16.44523-3.0062a35.47655,35.47655,0,0,1,19.29228,8.62983c5.2582,4.85294,8.68542,11.84811,8.53273,19.00188s-4.1649,14.27774-10.5589,17.48967"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <path
          d="M866.07881,343.53408c-5.87973-1.81115-8.59177-9.58474-5.96235-15.14691s9.35073-8.4748,15.45411-7.70005,11.48144,4.69043,15.191,9.59865,5.9468,10.759,7.81178,16.62189,3.416,11.85261,5.96329,17.45286a54.16842,54.16842,0,0,0,44.45006,30.94259c4.129.29914,8.41394.08482,12.17689-1.64105s6.9186-5.20542,7.34024-9.32376c2.14172,14.629-6.36878,30.17609-19.84618,36.25519s-30.76454,2.16827-40.313-9.11989c-9.33112-11.03123-10.77627-26.60551-10.55183-41.05222.06155-3.96158.20762-7.998-.88686-11.80587s-3.68466-7.4286-7.45762-8.638c-3.04412-.97577-6.34107-.27238-9.462.41942s-6.43481,1.35765-9.4566.31479-5.45494-4.4792-4.23735-7.43492"
          transform="translate(-231.16718 -153)"
          fill="#2f2e41"
        />
        <path
          d="M963.24986,747h-264a1,1,0,1,1,0-2h264a1,1,0,0,1,0,2Z"
          transform="translate(-231.16718 -153)"
          fill="#ccc"
        />
      </svg>
    );
  }
}
