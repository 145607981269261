import React, { Component } from "react";

export default class CertificationImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="1089.86663"
        height="822.50661"
        viewBox="0 0 1089.86663 822.50661"
      >
        <title>certificate</title>
        <path
          d="M988.80331,484.3133c0,126.51-74.17,238.43-187.87,306.56-72.9,43.69-162.06,69.38-258.33,69.38-128.25,0-226.08-48.47-299.67-122.72-67.83-68.42-115.06-158.75-146.53-253.22-140.49-421.79,94.2-536.86,446.2-375.94C766.72329,210.84327,988.80331,276.69331,988.80331,484.3133Z"
          transform="translate(-55.06669 -38.74669)"
          fill={theme.imageHighlight}
        />
        <path
          d="M800.93331,263.50331v528.37c-72.9,43.69-162.06,69.38-258.33,69.38-128.25,0-226.08-48.47-299.67-122.72v-475.03Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#f2f2f2"
        />
        <path
          d="M296.60406,520.9551a349.10769,349.10769,0,0,1,90.1557-16.07316,282.15087,282.15087,0,0,1,45.97047,1.7986,287.56233,287.56233,0,0,1,44.61626,9.55207c14.4206,4.17522,28.64209,8.99849,43.01405,13.33329a401.17806,401.17806,0,0,0,44.45525,10.84819,376.87356,376.87356,0,0,0,45.16986,5.38371c15.00305.87194,30.15309,1.0421,45.11414-.55621a138.0256,138.0256,0,0,0,40.59011-10.28933A123.97053,123.97053,0,0,0,728.70055,513.779q3.67222-3.2781,7.06609-6.84515c1.33469-1.39522-.78353-3.51978-2.12132-2.12132-19.12461,19.99191-44.26195,32.38806-71.527,36.52762-14.853,2.25509-29.98981,2.38592-44.97348,1.80438a370.06082,370.06082,0,0,1-45.71483-4.619A382.03123,382.03123,0,0,1,527.155,528.4477c-14.28794-4.13931-28.38132-8.91171-42.61931-13.21479a320.72782,320.72782,0,0,0-43.87857-10.45407,275.93839,275.93839,0,0,0-45.01855-3.12473A344.20143,344.20143,0,0,0,306.666,514.82967q-5.45484,1.53164-10.85948,3.23259c-1.836.5756-1.05209,3.47271.79752,2.89284Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M296.60406,596.9551a349.10769,349.10769,0,0,1,90.1557-16.07316,282.15087,282.15087,0,0,1,45.97047,1.7986,287.56233,287.56233,0,0,1,44.61626,9.55207c14.4206,4.17522,28.64209,8.99849,43.01405,13.33329a401.17806,401.17806,0,0,0,44.45525,10.84819,376.87356,376.87356,0,0,0,45.16986,5.38371c15.00305.87194,30.15309,1.0421,45.11414-.55621a138.0256,138.0256,0,0,0,40.59011-10.28933A123.97053,123.97053,0,0,0,728.70055,589.779q3.67222-3.2781,7.06609-6.84515c1.33469-1.39522-.78353-3.51978-2.12132-2.12132-19.12461,19.99191-44.26195,32.38806-71.527,36.52762-14.853,2.25509-29.98981,2.38592-44.97348,1.80438a370.06082,370.06082,0,0,1-45.71483-4.619A382.03123,382.03123,0,0,1,527.155,604.4477c-14.28794-4.13931-28.38132-8.91171-42.61931-13.21479a320.72782,320.72782,0,0,0-43.87857-10.45407,275.93839,275.93839,0,0,0-45.01855-3.12473A344.20143,344.20143,0,0,0,306.666,590.82967q-5.45484,1.53164-10.85948,3.23259c-1.836.5756-1.05209,3.47271.79752,2.89284Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M296.60406,451.9551a349.10769,349.10769,0,0,1,90.1557-16.07316,282.15087,282.15087,0,0,1,45.97047,1.7986,287.56233,287.56233,0,0,1,44.61626,9.55207c14.4206,4.17522,28.64209,8.99849,43.01405,13.33329a401.17806,401.17806,0,0,0,44.45525,10.84819,376.87356,376.87356,0,0,0,45.16986,5.38371c15.00305.87194,30.15309,1.0421,45.11414-.55621a138.0256,138.0256,0,0,0,40.59011-10.28933A123.97053,123.97053,0,0,0,728.70055,444.779q3.67222-3.2781,7.06609-6.84515c1.33469-1.39522-.78353-3.51978-2.12132-2.12132-19.12461,19.99191-44.26195,32.38806-71.527,36.52762-14.853,2.25509-29.98981,2.38592-44.97348,1.80438A370.06082,370.06082,0,0,1,571.43,469.52557,382.03123,382.03123,0,0,1,527.155,459.4477c-14.28794-4.13931-28.38132-8.91171-42.61931-13.21479a320.72782,320.72782,0,0,0-43.87857-10.45407,275.93839,275.93839,0,0,0-45.01855-3.12473A344.20143,344.20143,0,0,0,306.666,445.82967q-5.45484,1.53163-10.85948,3.23259c-1.836.5756-1.05209,3.47271.79752,2.89284Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M293.02966,321.49617c7.25838-3.21556,15.5732-2.65483,23.1061-.79035,8.12236,2.01037,15.771,5.50661,23.76662,7.924a82.27145,82.27145,0,0,0,43.97788.83424,81.06329,81.06329,0,0,0,23.03191-9.8719c1.63065-1.025.12677-3.62188-1.51415-2.59041a79.4874,79.4874,0,0,1-42.31964,12.06472,77.64725,77.64725,0,0,1-22.15352-3.262c-7.93732-2.3799-15.52474-5.83513-23.56552-7.88447-8.51912-2.17125-17.65485-2.6421-25.84384.98574-1.76158.7804-.237,3.36618,1.51416,2.59041Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <circle
          cx="460.86663"
          cy="696.75661"
          r="46"
          fill={theme.imageHighlight}
        />
        <circle
          cx="212.86663"
          cy="105.75661"
          r="25"
          fill={theme.compImgHighlight}
        />
        <circle
          cx="431.86663"
          cy="176.75661"
          r="17"
          fill={theme.compImgHighlight}
        />
        <circle
          cx="155.86663"
          cy="339.75661"
          r="17"
          fill={theme.compImgHighlight}
        />
        <rect
          x="85.547"
          y="213.46424"
          width="1.8747"
          height="335.24432"
          fill="#3f3d56"
        />
        <ellipse
          cx="86.84593"
          cy="436.55402"
          rx="36.55673"
          ry="83.42433"
          fill="#3f3d56"
        />
        <ellipse
          cx="86.84593"
          cy="246.27156"
          rx="36.55673"
          ry="83.42433"
          fill="#3f3d56"
        />
        <path
          d="M142.81061,216.86068l-1.796-.53824c.1135-.379,11.13471-38.14721-6.962-62.4607-10.22116-13.73165-27.76036-20.69451-52.13059-20.69451v-1.87471c24.9991,0,43.04588,7.21917,53.6396,21.457C154.21857,177.82509,142.92686,216.47347,142.81061,216.86068Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <circle cx="26.8554" cy="93.48318" r="23.4338" fill="#3f3d56" />
        <path
          d="M825.8065,663.424,808.274,824.72309H929.24832l5.25976-12.27276,7.013,12.27276h113.96133s-8.76625-166.55887-24.54551-171.81863S825.8065,663.424,825.8065,663.424Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#2f2e41"
        />
        <circle cx="874.18164" cy="209.15673" r="61.36379" fill="#ffb8b8" />
        <path
          d="M894.1833,286.475s5.25975,56.104,0,59.61054,84.15606,1.75326,84.15606,1.75326-12.27276-50.84429,0-75.38981Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#ffb8b8"
        />
        <path
          d="M925.74182,333.81273s45.11322,7.02643,48.85538-7.00629l23.02792,31.55181,1.75325,315.58523s-187.59788,22.79227-194.61089-14.026,71.88331-327.858,71.88331-327.858l19.86216-1.28572S922.23532,333.81273,925.74182,333.81273Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#d0cde1"
        />
        <path
          d="M896.21439,316.37533l-30.08311,10.4244-85.90931,21.039,3.5065,210.39015s-73.63655,210.39015-40.32478,226.16941,80.64956-21.039,94.67557-47.33779,63.117-390.975,63.117-397.988S896.21439,316.37533,896.21439,316.37533Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M973.47025,316.37533l11.88211,10.4244,84.15606,38.57153-42.078,212.1434s56.104,192.85764,35.065,203.37715-52.59754,40.32478-59.61054,22.79226S954.57513,335.7562,973.47025,316.37533Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M1022.17064,596.80042s-78.89631-33.31177-80.64956-3.5065,89.41582,50.84429,89.41582,42.078S1022.17064,596.80042,1022.17064,596.80042Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#ffb8b8"
        />
        <path
          d="M829.313,584.52767s91.16907,68.37679,56.104,85.90931-80.64956-61.3638-80.64956-61.3638Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#ffb8b8"
        />
        <path
          d="M956.49132,158.01513a3.38451,3.38451,0,0,1,3.25676-1.93273c-28.09345-1.68046-57.68462,1.59391-81.18765,17.0753-6.58229,4.33573-12.72923,9.6376-20.22289,12.081l6.08267,4.77011-7.26763,2.21543,10.69544,6.91263a9.27045,9.27045,0,0,1-8.82861,2.95773c4.64818,1.358,8.07046,5.49439,9.80869,10.01416s2.05052,9.44217,2.34418,14.27575c1.79656-6.9743,9.408-11.07972,16.59785-11.49687s14.2019,1.95512,21.19907,3.66045a117.27154,117.27154,0,0,0,46.548,1.79065c-5.25649,10.54877-.82242,23.13119,3.63955,34.03981L968.066,276.1598c4.94626.49749,8.44239-4.42141,12.16886-7.71176,3.39641-2.99892-10.12543,23.37638-8.03185,22.41,2.71982-1.25539,31.42131-32.94373,32.76537-35.62084,6.13213-12.21409,9.81123-25.8377,9.25789-39.4935s-5.55-27.31832-14.96291-37.2275c-6.39033-6.72714-15.23132-7.90529-21.746-13.49635-2.56408-2.20055-2.57349-3.91969-6.09717-5.20273C970.24979,159.391,956.68486,157.58563,956.49132,158.01513Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#2f2e41"
        />
        <path
          d="M792.49472,353.0985,780.222,347.83875,682.0399,509.13786s-17.53252,42.078,14.026,61.3638S801.261,647.64471,801.261,647.64471l42.078-64.8703s-85.90931-40.32478-80.64956-56.104,33.31178-57.85729,33.31178-57.85729Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
        <path
          d="M1046.71616,361.86476l22.79226,3.5065s94.67557,185.84463,71.88331,220.90966S1025.67714,670.437,1025.67714,670.437s-1.75325-57.85729-17.53251-78.89631l54.35079-21.039-29.80527-115.71459Z"
          transform="translate(-55.06669 -38.74669)"
          fill="#3f3d56"
        />
      </svg>
    );
  }
}
